.fez-icon {
  display: inline-block;
  vertical-align: text-top;

  &.scopus::before {
    content: url(~/src/images/scopus_icon.svg);
  }
  &.pubmed::before {
    content: url(~/src/images/pubmed_icon.svg);
  }
  &.wos::before, &.researcher::before {
    content: url(~/src/images/thomson_icon.svg);
  }
  &.thomson::before, &.researcher::before {
       content: url(~/src/images/thomson_icon.svg);
  }
  &.orcid::before {
    content: url(~/src/images/orcid_icon.svg);
  }
  &.altmetric::before {
    content: url(~/src/images/altmetric_icon.svg);
  }
  &.google::before, &.google_scholar::before {
    content: url(~/src/images/google_scholar_icon.svg);
  }
  &.dimensions::before {
    content: url(~/src/images/dimensions_icon.svg);
  }

  &.cc-by::before {
    content: url(~/src/images/creativecommons/by.png);
  }

  &.cc-by-nd::before {
    content: url(~/src/images/creativecommons/by_nd.png);
  }

  &.cc-by-nc::before {
    content: url(~/src/images/creativecommons/by_nc.png);
  }

  &.cc-by-nc-nd::before {
    content: url(~/src/images/creativecommons/by_nc_nd.png);
  }

  &.cc-by-nc-sa::before {
    content: url(~/src/images/creativecommons/by_nc_sa.png);
  }

  &.cc-by-sa::before {
    content: url(~/src/images/creativecommons/by_sa.png);
  }

  &.xsmall {
    width: 6px;
    height: 6px;
  }
  &.small {
    width: 12px;
    height: 12px;
  }
  &.medium {
    width: 14px;
    height: 14px;
  }
  &.large {
    width: 16px;
    height: 16px;
  }
  &.xlarge {
    width: 18px;
    height: 18px;
  }
  &.xxlarge {
    width: 24px;
    height: 24px;
  }
  &.xxxlarge {
    width: 70px;
    height: 70px;
  }
  &.dashboard {
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    width: 40px;
    height: 40px;
    &.orcid::before {
      zoom: 0.8;
    }
  }

  &.license .openExternalUrlIcon {
    display: none !important;
  }
}

// Override search input clear button icon with material design icon
input[type="search"] {
  -webkit-appearance: textfield !important;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url(~/src/images/baseline-close-24px.svg);
    background-size: cover;
  }
}
