// Charts styles from toolbox
@use "./charts";
// SVG Icons
@use "./icons";
// Import the fez-frontend modules styles
@use './components';
// Print styles
@use "./print";
// Browser update script styles
@use './browserUpdate';

html, body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: #F7F7F7;
}

a, button.MuiLink-button:enabled {
  color: #3872a8;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.noHover {
    &:hover {
      text-decoration: none;
    }
  }
}

.smallLogo,
.largeLogo {
  color: transparent;
  background: center center url(~/src/images/uq-logo-white-minimal.svg) no-repeat;
}

.largeLogo {
  background-image: url('~/src/images/uq-logo-white-2018.svg');
}

.layout-fill {
  margin: 0; padding: 0;
  max-height: 100%;
  height: 100%;
}

.layout-card {
  max-width: 1200px;
  margin: 24px auto;
  width: 90%;
  padding: 0;
}

// The containers which houses content
.content-container {
  width: 100%;
  height: calc(100% - 75px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 70px;
  padding-bottom: 24px;
}

// Citation styles
.citationContent {
  .citationJournalName, .citationTitle, .citationNewspaper, .citationVolumeNumber {
    font-style: italic;
  }
}

// External links that don't use built in component
.externalLink {
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100% !important;
  min-width: 0;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  
  .externalLinkIcon {
    color: inherit;
    font-size: 0.66rem;
    float: right;
    margin-left: 0.25rem;
  }
}

// allow dropdown list items to wrap to a new line
// for phone & tablet breakpoints
ul[role='listbox']{
  @media (max-width: 959.96px){
    li{
      white-space: normal;
    }
  }
}
// allow the selected item in a dropdown list to
// wrap to a new line for phone & tablet breakpoints
div[class*='MuiSelect-selectMenu-']{
  @media (max-width: 959.96px){
      white-space: normal;
  }
}

@keyframes animateFadeIn {
  0% { opacity: 0 };
  100% { opacity: 1 };
};